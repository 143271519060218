.form {

    input.ng-invalid.ng-touched {
        border: $color-error 1px solid;
        color: $color-error;


        &::-webkit-input-placeholder, ~ label {
            color: $color-error;
        }
    }
    .error-box {
        display: inline-block;
        position: absolute;
        right: 0;
        font-size: $font-12;
        min-width: 14rem;
        height: 2rem;
        background: $color-error-light;
        color: $color-error;
        opacity: 1;
        text-align: center;
        line-height: 2rem;

        &::before {
            content: '';
            position: absolute;
            right: 0.5rem;
            top: -0.4rem;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $color-error-light;
            transition: 0.5s;
        }
    }

    .input-icon {
        position: absolute;
        bottom: 50%;
        right: 5px;
        color: #545454;
        transform: translateY(50%);
        cursor: pointer;

        &--error {
            color: $color-error;
        }
    }

    &__input {
        border-radius: 1rem;
        background-color: $color-white;
        border: none;
        font-size: $font-18;
        width: 100%;
        color: $color-black;
        opacity: 1;
        display: block;
        transition: all .3s;
        font-weight: $font-weight-regular;
        height: 6rem;

        &--border {
                border: 1px solid #CECECE;
                border-radius: 10px;
                opacity: 1;
            }

            &--search {
                height: 6rem;
            }

        @include respond(phone) {
            margin-bottom: 5px;
        }

        &:focus, &:hover {
            outline: none;
            border: 1px solid $color-primary-light;
            box-shadow: none;
        }

        &::-webkit-input-placeholder {
            font-size: $font-18;
            font-weight: $font-weight-light;
            color: $color-dark-grey;
            padding: 1rem 0.75rem
        }
    }

    &__radio-group {
        width: 100%;
        display: inline-block;
    }

}

//Bootstrap Overrides 
.form-floating > .form-control, .form-floating > .form-select{
    height: 6rem;
}


.form-floating > .form-control:not(:placeholder-shown) ~ label {
    transform: scale(0.65) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:not(:focus) ~ label {
    height: auto;
    top: .5rem;
}

.form-floating > .form-control:focus ~ label {
    transform: scale(0.75) translateY(-0.5rem) translateX(0.15rem);
}