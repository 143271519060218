.filter-menu {
    background: $color-primary-light;
    color: $color-white;

    .filter__option {
        margin: 0 5px 2px;
    }
}

.filter {
    background: $color-primary-light;
    padding: 5px 0;
    color: $color-white;

    &__list {
        margin-left: 5px;
    }

    &__option {
        background: rgba($color-white, 0.3);
        padding: 5px;
        margin-right: 5px;
        min-width: 20rem;
        height: 3rem;

        @include respond(phone) {
            min-width: 10rem;
        }
    }
}


.refine {
    background: $color-secondary-blue-light;
    padding: 5px 0;
    color: $color-white;
    
}

.sort {
    background: $color-primary-dark;
    padding: 5rem 0;
    color: $color-white;

    @include respond(phone) {
        padding: 3rem 0;
     }

    .social-share {
        mat-icon {
            margin-right: 2px;
        }
    }
}

.filter-wrapper {
    &.filter-popover {
        height: 100%;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        right:0;
        background: $color-white;
    }
    .content {
        height: 80vh;
        overflow: scroll;

        &::-webkit-scrollbar {
            width: 10px;
          }
           
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background:$color-light-grey; 
            border-radius: 10px;
            padding-right: 1px;
          }
    }

    .static-footer {
        background: $color-secondary-blue-light;
    }
}

.filter-control-wrapper {

    &__header {
        border-bottom: 1px solid $color-light-grey;
        border-left: 1px solid #e5e5e5;
        border-right: 1px solid #e5e5e5;
        border-top: 1px solid #e5e5e5;
        background: $color-white 0% 0% no-repeat padding-box;
        padding: 1rem 3rem;

        & > * {
            line-height: inherit;
        }

        .selected-values {
            font-size: $font-12;
            font-weight: $font-weight-light;
            color: $color-primary-hover;
        }
    }

    &__content {
        padding: 2rem 3rem;
        background: $color-white 0% 0% no-repeat padding-box;
        border-left: 1px solid #e5e5e5;
        border-right: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        opacity: 1;
        height: 25rem;

        @include respond(phone) {
            height: auto;
        }
    }
    overflow: hidden;
    box-shadow: 0px 2px 4px #0000001A;
}