@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) { @content };    //600px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) { @content };     //900px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content };    //1200px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 112.5em) { @content };    //1800
    }
}

@mixin icon-wrapper {
    // padding: 25px;
    padding: 12px;
    display: inline-block;
    border-radius: 50%;

    // border-radius: 50%;
    // display: flex;
    // align-items: center;
    // text-align: center;

    // display: table-cell;
    // vertical-align: middle;
    // border-radius: 50%;
    // text-align: center;
    // height: 100px;
    // width: 100px;
}