
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Anthem-UI-primary: mat.define-palette(mat.$blue-palette, 600);
$Anthem-UI-accent: mat.define-palette(mat.$green-palette, 400);

// The warn palette is optional (defaults to red).
$Anthem-UI-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Anthem-UI-theme: mat.define-light-theme((
  color: (
    primary: $Anthem-UI-primary,
    accent: $Anthem-UI-accent,
    warn: $Anthem-UI-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Anthem-UI-theme);

/* You can add global styles to this file, and also import other style files */

// Abstracts 
@import 'scss/abstracts/mixins';
@import 'scss/abstracts/variables';

@import '../node_modules/bootstrap/scss/bootstrap.scss';

//Components
@import 'scss/components/tabs';
@import 'scss/components/search-box';
@import 'scss/components/form';
@import 'scss/components/button';
@import 'scss/components/checkbox';
@import 'scss/components/specialties';
@import 'scss/components/filter';
@import 'scss/components/card';
@import 'scss/components/tabs';
@import 'scss/components/toast';
@import 'scss/components/pagination';


// Base Styles
@import 'scss/base/base';
@import 'scss/base/animations';


// Typography
@import 'scss/base/typography';

//Utilities
@import 'scss/base/utilities';

//Layout Styles
@import 'scss/layout/header';
@import 'scss/layout/footer';
@import 'scss/layout/globalFaq';


//Pages Styles
@import 'scss/pages/pageDefaults';
@import 'scss/pages/providerSearch';
@import 'scss/pages/providerDetails';
@import 'scss/pages/disclaimer';



