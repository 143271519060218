

.button {
    &,
    &:link,
    &:visited {
        text-decoration: none;
        padding: 1.5rem 4rem;
        display: inline-block;
        border-radius: 1rem;
        transition: all .2s;
        position: relative;
        color: $color-white;
        min-height: 40px;

        //Change for the <button> element
        border: none;
        cursor: pointer;
    }

    @include respond(phone) {
        margin-bottom: 5px;
     }
    
    &:hover:not(:disabled) {
        box-shadow: 0 1rem 2rem rgba($color-black,.2);
    }
    
    &:active:not(:disabled),
    &:focus:not(:disabled) {
        outline: none;
        box-shadow: 0 .5rem 1rem rgba($color-black,.2);
    }

    &__icon {

        margin-right:.5rem;
    }

    &--xs {
        width:12.5rem;
        height: 2.6rem;
        padding: 0;
    }

    &--small {
       width: 13.5rem;
       height: 4.2rem;
       padding: 0;
    }

    &--small-icon {
        width: 5.5rem;
        height: 4.2rem;
        padding: 0;
    }

    &--medium-icon {
        width: 20rem;
        height: 4.2rem;
        padding: 0;

        @include respond(phone) {
            width: 4rem;
            height: 2.5rem;
            font-size: 0;
         }
    }

    &--green {
        background: $color-secondary-light;
        color: $color-white;

        &:hover:not(:disabled) {
            background: $color-secondary-hover;
        }

        &::after {
            background: $color-secondary-light;
        }
    }

    &--blue {
        background: $color-primary-hover;
        width: 100%;
    }  

    &--transparent {
        background: transparent;
        border: 1px solid $color-white;
    }  
    &--white {
        background: $color-white;
        color: $color-secondary-blue-light;
    }  
}

.button-text {
    &,
    &:link,
    &:visited {
        display: inline-block;
        text-decoration: none;
        padding: 5px;

        &:hover {
            text-decoration: underline;
        }
    }

    &--underlined {
        text-decoration: underline;
    }

    &--grey {
        color: $color-extra-light-grey;
    }
    &--blue {
        color: $color-primary-light;
    }
    &--green {
        color: $color-secondary-light;
    }
}

button:disabled,
button[disabled]{
  border: 1px solid $color-disabled;
  background-color: $color-disabled;
  color: $color-white;
  cursor: none;
}
