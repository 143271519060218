.specialties-box {
    text-align: center;
    z-index: 1;

    &__icon-wrapper {
        // background-color: $color-secondary-light;
        @include icon-wrapper;

        &:hover {
            cursor: pointer;
        }

        &:focus {
            // background-color:$color-white;
            // transition: all 3s;

            // .specialties-box__icon {
            //     // filter: brightness(0) saturate(100%) invert(64%) sepia(8%) saturate(1586%) hue-rotate(79deg) brightness(91%) contrast(89%);
            //     transition: all 3s;
            // }
        }
    }

    &__icon {
        width:8rem;
        //filter: brightness(0) invert(1);
    }

    &__text {
        display: block;
       font-size: 1.5rem;
    }
}