.header {
    font-size: 1.4rem;
    padding: 2rem;
    align-items: center;
    height: 13.3rem;
    position: relative;

    .navbar-toggler {
        margin-left: 2rem;
    }

    @include respond(phone) {
        height: 7rem;
        padding: 0;
    }

    &__provider-logos {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);

        @include respond(tab-port) {
            img {
                height: auto;
                width: auto;
                max-width: 30rem;
            }
        }

        @include respond(phone) {
            img {
                height: auto;
                width: auto;
                max-width: 14rem;
            }
        }


    }

    &__icon-box {
        display: inline-block;

        a {
            &:not(:last-of-type) {
                margin-right: 1rem;
                margin-left: 2rem;

                @include respond(big-desktop) {
                    margin-left: 2rem;
                }

            }
        }

        img {
            height: 2.4rem;
            width: 2.4rem;
            filter: brightness(0) saturate(100%) invert(8%) sepia(11%) saturate(400%) hue-rotate(289deg) brightness(91%) contrast(83%);

            &:hover {
                transition: all .2;
                filter: brightness(0) saturate(100%) invert(57%) sepia(7%) saturate(8%) hue-rotate(332deg) brightness(93%) contrast(94%);
            }
        }
    }

    &__main-logo-box {
        img {
            margin-right: 2rem;

            @include respond(big-desktop) {
                margin-right: 5rem;
            }
        }

        @include respond(tab-port) {
            img {
                width: 14rem;
            }
        }

        @include respond(phone) {
            img {
                width: 7rem;
            }
        }

    }
}

.overlay {
    height: 100vh;
    opacity: 0;
    width: 0;
    overflow-x: hidden;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: $color-primary-light;
    transition: 0.3s;

    &__header {
        color: $color-white;
        height: 5rem;
        padding: 0 2rem;
        background: $color-menu-header;

        &-content {
            .title {
                h4 {
                    font-size: $font-22;
                    font-weight: $font-weight-regular;
                }
            }
        }
    }

    &__content {
        padding: 0 2rem;
        color: $color-white;
        margin-top: 2rem;


        ul {
            padding: 0;

            &>li {
                list-style: none;
            }
        }

        .menu-item {
            a {
                color: $color-white;
                text-decoration: none;
                font-size: $font-14;
                font-weight: $font-weight-regular;
                display: block;

                .material-icons {
                        margin-right: 5px;
                    }
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                right: 0;
                left: 0;
                margin-top: 5px;
                border-bottom: 1px solid rgba($color-white, 0.1);
            }
        }
    }
}

.network-name {
    background-color: $color-network-background;
    padding: 0.7rem 0;

    &--text {
        color: $color-network-name;
    }
}