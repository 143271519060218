.pagination {
    margin-top: 6.5rem;
}

.page-link.active, .active > .page-link {
    color: $color-white;
    background-color: $color-primary-light;
    border-color:  $color-primary-light;
  }

  .page-link, .page-link.disabled, .disabled > .page-link  {
    color: $color-primary-light;
    border-color: $color-light-grey;

    &:hover {
        background-color: $color-primary-light;
        color: $color-white;
    }
  }