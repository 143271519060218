



.search-box {
    position: relative;

    mat-tab-header {
       background: linear-gradient(to right bottom, rgba($color-dark-grey, 0.7), rgba($color-dark-grey,0.7));

       .mat-mdc-tab-labels {
        @include respond(phone) {
            display: block;
        }
       }
    }

    .indicator {
            position: absolute;
            left: 0;
            margin-top: 8rem;
            top: 9.5rem;
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 15px solid $color-primary-light;
            transition: 0.5s;
    }
    
    .mat-mdc-tab {
        &.mdc-tab {
            height: 7rem;
            border: 1px solid rgba($color-white, 0.6);
            &:not(:last-child) {
                border-right: 1px solid rgba($color-white, 0.6);
            }
    
            &--active {
                background: $color-primary-light;
            }
            
            &:not(&--active):hover {
                background: $color-light-black;
            }
    
            .mdc-tab-indicator {
                display: none;
            }
        }
    }


    &__tab-wrapper {
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    &__tab-icon {
        width: 2rem;
        margin-right: 2px;
        filter: brightness(0) invert(1);
    }

    &__tab-label {
        color:white;
        margin-left: 5px;
    }

    &__form {
       width: 100%;
       padding: 2.5rem;
       min-height: 22rem;
       height: 100%;
       background: $color-primary-light;
       border-radius: 5px;
    }

    &__advanced-search {
        background: #C7E1F8 0% 0% no-repeat padding-box;
        border-radius: 5px;
        height: 7rem;
        position: absolute;
        left: 2;
        margin-top: -5px; 
        text-align: center;

        a {
            text-decoration: none;
            color: $color-primary-blue;
            line-height: 7rem;
            padding: 0 1rem;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}