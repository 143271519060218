.card {

    padding: 1rem;

    &.mat-mdc-card {
        border-radius: 12px;
        box-shadow: 0px 16px 30px 0px rgba(0, 0, 0, 0.10);
        
    }


    mat-card-content {
        padding: 1rem;
    }

    &__icon-wrapper {
        background-color: $color-network-background;
        @include icon_wrapper;

        @include respond(phone) {
            &.mat-mdc-card-avatar {
                width: 60px;
                height: 60px;
            }
        }
    }

    &__icon {
        width: 5rem;
    }

    &__card-header {
        border-bottom: 3px solid $color-light-grey;
        

        &.mat-mdc-card-header {
            padding: 15px 0px 15px;
        }

        mat-card-header,
        mat-card-title,
        mat-card-subtitle {
            padding: 0 !important;
        }

        &-subtitle {
            strong {
                color: $color-card-header;
                font-size: $font-18;
                font-weight: $font-weight-medium;
            }
            overflow-wrap:break-word;
        }

        &-title {
            display: block;
            overflow-wrap: break-word;
            padding: 50px;



            .name {
                color: $color-primary-light;
                text-decoration: underline;
            }

            p {
                color: $color-dark-grey;
            }
        }

        &-icons {
            .icon {
                width: 2rem;
            }
        }
    }



    &__detail {
        margin-right: 1rem;
        min-height: 20rem;
        position: relative;

        &:not(:last-child) {
            border-right: 1px solid $color-light-grey;
        }





        @include respond(phone) {
            min-height: 0px;
            padding: 1rem 0;

            &:not(:last-child) {
                border-right: none;
            }

            border-bottom: 1px solid $color-light-grey;
        }




        .title {
            color: $color-medium-grey;
            text-transform: uppercase;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                font-weight: $font-weight-regular;
                font-size: $font-16;
                text-decoration: none;
            }

        }
    }
}