.client-info {
    word-wrap: break-word;
    text-align: center;

    .text--blue {
        color: $color-footer-blue;
    }
}

.footer {
    background-color: $color-dark-grey;
    padding: 10rem 0;
    color: $color-light-grey;
    
    .p4 {
        font-size: $font-16;

        @include respond(phone) {
            font-size: 1.2rem;
        }

    }

    &__text {
        line-height: 1.6;
    }

    &__logo {
        @include respond(big-desktop) {
            margin-left: 10rem;
        }
    }

    &__links {
        ul {
            li {
                display: inline-block;
                margin: 5px;

                a {
                    font-size: $font-16;
                    font-weight: $font-weight-regular;
                    color: $color-light-grey;
                    text-decoration: underline;
                    cursor: pointer;

                    @include respond(phone) {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}