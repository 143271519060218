

.check-box {

    &--toggle {
        border-radius: 25px;
        background: rgba($color-white, 0.1);
        width: 20rem;

        label {
            color: $color-white;
            font-size: $font-18;
            font-weight: $font-weight-light;
        }

        .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
            border-color:  rgba($color-white, 0.1) !important;
            background-color: rgba($color-white, 0.1) !important;
        }

        .mdc-checkbox__background {
            border-radius: 20px;
        }
    }
}

.checkbox-list {
    height: 15rem;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 10px;
      }
       
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background:$color-light-grey; 
        border-radius: 10px;
        padding-right: 1px;
      }
}


//Overrides
mat-checkbox.mat-mdc-checkbox-checked label {
    color: $color-primary-light;
}
