input#toggleBtn {
    display: none;
}

.toggle:before,
.toggle:after{
    content:'';
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: $color-white;
    transition: background ease .3s, top ease .3s .3s, transform ease .3s;
}

.toggle {
    width: 15px;
    height: 15px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.toggle:before{
    width: 1px;
    margin: 3px auto;
}

.toggle:after{
    margin: auto 3px;
    height: 1px;
    box-shadow: none;
}

.icon {
    -moz-transition: all .5s linear;
    -webkit-transition: all .5s linear;
    transition: all .5s linear;
}

#toggleBtn:checked + .icon.rotate {
    transform: rotate(180deg);
    transition: all 0.4s ease;
  }

#toggleBtn:checked + .icon .toggle::before {
    transform: rotate(90deg);
  }

#toggleBtn:checked + .icon .toggle::after {
    background: transparent;
}