.page {
    background: $color-light-grey;
    &__header {
        padding: 1rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-white;
        padding: 5px;

        &--gray {
            background: $color-network-background;
        }
        &--border-top {
            border-top: 1px solid white;
        }
    }

    &__sub-header {
        @include respond(phone) {
            padding: 1rem 0;
         }
        position: relative;
        background: $color-primary-light;
        padding: 4rem 0;
        color: $color-white;

        .icon {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(6380%) hue-rotate(68deg) brightness(101%) contrast(102%);
        }
    }
}