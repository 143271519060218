.provider-search-wrapper {
    height: 90vh;
    background-image: url(../../assets/backgrounds/Anthem_Background_Crop_16_9.jpg);
    background-size: cover;
    background-position: bottom;
    position: relative;
    color: $color-white;
    overflow: auto;
}

.additional-resources {
    color: $color-white;

    &__header {
        background: $color-dark-grey;
        padding: 2rem;
    }

    &__content {
        padding: 10rem 0;
    }
}

.client-info {
    background: $color-light-grey;
    padding: 10rem 0;

    p {
        color: $color-black;
    }

    &__icon {
        margin: .5rem;
    }
}